import React, { useState, useEffect, useRef } from 'react';

function App() {
  const [tenant, setTenant] = useState('');
  const [previousTenants, setPreviousTenants] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  // Reference to the input element
  const inputRef = useRef(null);

  useEffect(() => {
    // Set focus on input field when component mounts
    inputRef.current.focus();

    // Retrieve previously saved tenants from local storage
    const savedTenants = JSON.parse(localStorage.getItem('tenants')) || [];
    setPreviousTenants(savedTenants);
  }, []);

  const checkTenant = async (tenantName) => {
    setIsLoading(true);
    setErrorMessage('');

    try {
      const response = await fetch('/api/check-tenant', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ name: tenantName }),
      });
      const result = await response.json();

      if (result.tenantExists) {
        let tenants = JSON.parse(localStorage.getItem('tenants')) || [];
        if (!tenants.includes(tenantName)) {
          tenants.push(tenantName);
          localStorage.setItem('tenants', JSON.stringify(tenants));
        }
        window.location.href = `https://${tenantName}.portal.nemesis.services`;
      } else {
        throw new Error('Tenant does not exist');
      }
    } catch (error) {
      setErrorMessage(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const removeTenant = (tenantToRemove) => {
    const updatedTenants = previousTenants.filter((t) => t !== tenantToRemove);
    setPreviousTenants(updatedTenants);
    localStorage.setItem('tenants', JSON.stringify(updatedTenants));
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      checkTenant(tenant);
    }
  };

  return (
    <div className="relative flex flex-col items-center justify-center min-h-screen bg-gradient-to-b from-black to-gray-900 font-poppins">
      {/* Background */}
      <div className="absolute inset-0 overflow-hidden z-0">
        <img
          src="/background-image.png" // Replace with your image
          alt="Background"
          className="w-full h-full object-cover opacity-60"
        />
      </div>

      {/* Main Content */}
      <div className="relative z-10 bg-gray-800 bg-opacity-95 backdrop-blur-md p-6 sm:p-10 rounded-lg shadow-2xl max-w-xs sm:max-w-md w-full mx-auto text-center">

        {/* Logo */}
        <div className="relative z-10 mb-8">
          <img src="/logo.svg" alt="Persistent Security Logo" className="mx-auto w-24 h-24 sm:w-32 sm:h-32" />
        </div>

        <h1 className="text-2xl sm:text-3xl font-bold text-[#fe602f] mb-6">Sign in to Nemesis</h1>
        <p className="text-gray-300 mb-4">Enter your Nemesis tenant name below.</p>

        {/* Input */}
        <div className="relative mb-4 flex items-center w-full">
          <input
            type="text"
            placeholder="coral-wizard"
            className="w-full sm:w-auto flex-grow py-2 sm:py-3 text-black bg-white border border-gray-300 rounded-l-md text-right font-medium tenant-input"
            value={tenant}
            onChange={(e) => setTenant(e.target.value)}
            onKeyPress={handleKeyPress} // Add keypress handler
            ref={inputRef} // Set reference to input element
            autoCapitalize="none"
            autoCorrect="off"
            style={{
              WebkitAppearance: 'none',
              borderRadius: '0.375rem 0 0 0.375rem',
            }}
          />
          <span className="domain-suffix flex-none px-3 sm py-2 sm:py-3 bg-white text-gray-700 rounded-r-md font-medium whitespace-nowrap">
            .portal.nemesis.services
          </span>
        </div>

        {/* List of previous tenants */}
        {previousTenants.length > 0 && (
          <div className="bg-gray-700 text-left p-4 rounded-lg mb-6">
            <h2 className="text-white text-lg mb-3 font-semibold">Your tenants</h2>
            {previousTenants.map((prevTenant, index) => (
              <div
                key={index}
                className="flex items-center justify-between bg-gray-800 p-3 mb-2 rounded-md text-white cursor-pointer hover:bg-gray-600"
                onClick={() => checkTenant(prevTenant)}
              >
                <div className="flex items-center">
                  <div className="bg-gray-600 w-8 h-8 sm:w-10 sm:h-10 flex items-center justify-center text-white font-bold rounded-md mr-3">
                    {prevTenant.charAt(0).toUpperCase()}
                  </div>
                  <div className="text-left">
                    <p className="font-bold">{prevTenant}</p>
                    <p className="text-xs sm:text-sm text-gray-400 font-light">
                      {`${prevTenant}.portal.nemesis.services`}
                    </p>
                  </div>
                </div>
                <button
                  className="text-gray-400 hover:text-white ml-4"
                  onClick={(e) => {
                    e.stopPropagation();
                    removeTenant(prevTenant);
                  }}
                >
                  ✕
                </button>
              </div>
            ))}
          </div>
        )}

        {/* Continue Button */}
        <button
          onClick={() => checkTenant(tenant)}
          className={`w-full py-2 sm:py-3 rounded-md text-white font-semibold bg-[#fe602f] hover:bg-[#e55527] transition-colors duration-300 ${
            isLoading ? 'opacity-50 cursor-not-allowed' : ''
          }`}
          disabled={isLoading}
        >
          {isLoading ? 'Loading...' : 'Continue'}
        </button>

        {errorMessage && <div className="mt-4 text-red-500">{errorMessage}</div>}
      </div>
    </div>
  );
}

export default App;
